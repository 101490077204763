import React, {useEffect, useState} from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import jscolor from "./jscolor";
import {withRouter} from 'react-router'
import ColoredSquare from "./ColoredSquare";

const Top = (props) => {
  return (
    <div className="top">
      <div className="titlebar">
        Planned Pooling
      </div>
      <div className="navbar">
        <Link to="/">the app</Link>
        <Link to="/about/">about</Link>
        <Link to="/examples/">examples</Link>
      </div>
    </div>
  )
};

const About = (props) => (
  <div>
    <h1>About</h1>
    <p>
      <h2>What this is:</h2>
      Sometimes knitters knit with yarn that has a repeating sequence of colors. Depending on the sequence of colors
      (and how many stitches of each) and the shape of the object being created (on this site, I'm simulating a square
      that's a certain number of stitches across), the colors can come out in different patterns. <em>Pooling</em> is
      when colors clump together forming 'pools' of the same color, but many different types of patterns can arise. This
      site should help you explore the possibilities, and perhaps help you plan a desired look.
    </p>
    <h2>Thanks</h2>
    <p>Many thanks to <a href="http://www.ravelry.com/designers/karla-stuebing">Karla Stuebing</a> who had the original
      idea to create a similar thing.</p>
    <p>Thanks to my spouse for giving me lots of advice and feedback along the way.</p>
    <h2>Copyright</h2>
    <p>I do not make any claim to images generated by this site.</p>
    <h2>Contact</h2>
    plannedpooling@gmail.com (however, I apologize in advance that I may not have time to reply)
  </div>

);

const Examples = (props) => <div>
  <h1>Some examples:</h1>

  <img alt='' src="/media/yellow_orange_red_cropped.JPG" height="300"/>
  <img alt='' src="/media/yellow_orange_red_knitted.jpg" height="300"/>

  <p/>

  <img alt='' src="/media/schema.jpg" height="300"/>
  <img alt='' src="/media/xxx2.jpg" height="300"/>

  <p/>

  <img alt='' src="/media/from_jill/pocket_square_blue.jpg" height="300"/>
  <img alt='' src="/media/from_jill/argyle2.jpg" height="300"/>
  (Thanks to Jill Spreenberg Robinson for this one.)
  <p/>

  <img alt='' src="/media/from_samantha/Screenshot_2015-09-21-00-43-17.png" height="300"/>
  <img alt='' src="/media/from_samantha/IMG_20150921_243449236.jpg" height="300"/>
  (Thanks Samantha!)


</div>;

const ColorChooser = (props) => {
  useEffect(() => {
    // This part makes the color chooser (js color) work. It's really gross. React does not enjoy working with other
    // tools that also want to edit the DOM.
    const inputElement = document.querySelector('#colorChooser' + props.i)
    // stupid hacks:
    // - call jscolor.color to make the color chooser
    // - call addEventListener to listen to changes... for some reason "onChange" isn't working for that
    //
    // don't want to do this multiple times on the same dom element, so keep track of whether we've done it with inputElement.doneWithStupidHacks
    inputElement.setColor = props.setColor // ugh, if we just used props.setColor in the addEventListener below, it wouldn't be updated when it needs to
    new jscolor.color(inputElement) // if we don't call this here, the input is not recolered on some changes (e.g. removing one)
    if (!inputElement.doneWithStupidHacks) {
      // haha this kills all the later colors. err, not anymore now that we
      inputElement.addEventListener('change', (e) => e.target.setColor(e.target.value))
      inputElement.doneWithStupidHacks = true;
    }
  });

  return (
    <div>
      <label>Color {props.i}</label>
      <input id={"colorChooser" + props.i} value={props.color} className="jscolor"
             onChange={(event) => props.setColor(event.target.value)}/>
      Number of stitches: <input value={props.stitchCount}
                                 onChange={(event) => props.setStitchCount(event.target.value)}/>
      <button onClick={props.removeColor} disabled={props.onlyOneColor}>remove this color</button>
    </div>
  )
};

const ColorChoosers = (props) => {
  const removeColorI = i =>
    () => {
      // debugger;
      props.setColors(props.colors.slice(0, i).concat(props.colors.slice(i + 1)))
      props.setStitchCounts(props.stitchCounts.slice(0, i).concat(props.stitchCounts.slice(i + 1)))
    };

  const setColorI = i =>
    (newColor) => {
      console.log('setting color: ' + i + ' to ' + newColor)
      console.log(props.colors)
      const newColors = [...props.colors];
      // debugger;
      newColors[i] = newColor;
      console.log(newColors)
      props.setColors(newColors)
    };

  const setStitchCountI = i =>
    (newCount) => {
      const newCounts = [...props.stitchCounts];
      newCounts[i] = newCount;
      props.setStitchCounts(newCounts)
    }

  return (
    <div>
      <div className="choicesSection">
      {props.colors.map((color, i) => (
        <ColorChooser
          setStitchCount={setStitchCountI(i)}
          stitchCount={props.stitchCounts[i]}
          setColor={setColorI(i)}
          onlyOneColor={props.colors.length === 1}
          removeColor={removeColorI(i)} color={color} i={i}/>))}
      </div>
      <div className='choicesSection'>
        <button onClick={() => {
          props.setColors([...props.colors, newRandomColor()])
          props.setStitchCounts([...props.stitchCounts, 5])
        }
        }>Add a color
        </button>
      </div>
    </div>)
};

const newRandomColor = () => (
  Math.floor(Math.random() * 16777215).toString(16)
)

const LengthController = (props) => (
  <div>
    <div className="lengthButtonsContainer">
      <button disabled={props.numStitches <= 6} onClick={props.addStitches(-5)}>5 Stitches Shorter</button>
      <button disabled={props.numStitches <= 1} onClick={props.addStitches(-1)}>1 Stitch Shorter</button>
      <button onClick={props.addStitches(1)}>1 Stitch Longer</button>
      <button onClick={props.addStitches(5)}>5 Stitches Longer</button>
    </div>
    <div className="choicesSection">
      <label htmlFor="id_stitches">Stitches in a row</label>:
      <input type="text" value={props.numStitches} onChange={e => props.setStitches(+e.target.value)} id="numStitchesRow"/>
    </div>
  </div>
);

const Pooler_ = (props) => {
  let stateFromUrl = {};
  if (window.location.hash) {
    stateFromUrl = JSON.parse(decodeURI(window.location.hash.slice(1)));
  }

  const [numStitches, setNumStitches] = useState(stateFromUrl.unitsWide || 51);
  const [colors, setColors] = useState(
    (stateFromUrl.colorChoices && stateFromUrl.colorChoices.map(s => s.replace('#', ''))) || ["BF5FFF", "2A1DDE"])
  const [stitchCounts, setStitchCounts] = useState(stateFromUrl.numStitchesChoices || [4, 9])
  const [type, setType] = useState(stateFromUrl.type || 'flat')

  console.log(colors)

  const state = {
    "nColors": colors.length,
    "colorChoices": colors.map(s => '#' + s),  // add hashes to match old format
    "numStitchesChoices": stitchCounts,
    "unitsWide": numStitches,
    "type": type
  };
  console.log(state)
  // using props.history.push would trigger a re-render, which we do not want
  window.location.hash = JSON.stringify(state)
  const addStitches = numStitchesDelta =>
    () => {
      setNumStitches(numStitches + numStitchesDelta)
    };

  const onRadioButtonChange = (e) => e.target.checked && setType(e.target.value)

  return (
    <div id="pooler">
      <LengthController setStitches={setNumStitches} addStitches={addStitches} numStitches={numStitches}/>
      <ColorChoosers colors={colors} stitchCounts={stitchCounts} setColors={setColors}
                     setStitchCounts={setStitchCounts}/>
      <div>
        <label id="typeLabel">Type of knitting:</label>
        <div id="typeChoicesContainer">
          <input
            type="radio"
            name="type"
            value="flat" onChange={onRadioButtonChange}
            checked={type === 'flat'}
          /> Flat (back and forth) knitting<br/>
          <input
            type="radio"
            name="type"
            value="circular"
            onChange={onRadioButtonChange}
            checked={type === 'circular'}
          /> Circular (in the round) knitting<br/>
        </div>

      </div>

      <ColoredSquare
        colors={colors}
        numStitches={numStitches}
        stitchCounts={stitchCounts}
        type={type}/>
      <p>
        <a href="https://www.youtube.com/watch?v=fKpdqAk02Dw">Video explanation</a> of how to use this site for crochet
        (knitting is a similar idea).
      </p>   <p>plannedpooling@gmail.com.</p>
    </div>

  )
};
const Pooler = withRouter(Pooler_);


const App = (props) => {
  return (
    <Router>
      <Top/>
      <Switch>
        <Route path="/about"><About/></Route>
        <Route path="/examples"><Examples/></Route>
        <Route path="/" exact><Pooler/></Route>
        <Route path="/"><h1>Not Found</h1></Route>
      </Switch>
    </Router>
  );
}

export default App;
